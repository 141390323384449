import Css from "./style.module.scss";

import { TableCell } from "nlib/ui/Table";
import ActionCellContent from "nlib/common/ActionCellContent";
import React, { useCallback, useMemo } from "react";
import classNames from "classnames";

const stopPropagation = (event) => event.stopPropagation();

const ActionsCell = ({ className, email, disabledEdit, disabledRevoke, onEdit, onRevoke }) => {
  const menuItems = useMemo(() => {
    return [
      {
        action: "edit",
        disabled: disabledEdit
      },
      {
        action: "revoke",
        disabled: disabledRevoke
      }
    ];
  }, [disabledEdit, disabledRevoke]);

  const handleMenuItemClick = useCallback((action) => {
    switch (action) {
      case "edit":
        onEdit(email);
        break;
      case "revoke":
        onRevoke(email);
        break;
    }
  }, [email, onEdit, onRevoke]);

  return (
    <TableCell className={classNames(Css.actionsCell, className)} onClick={stopPropagation}>
      <ActionCellContent
        menuItems={menuItems}
        onClick={handleMenuItemClick} />
    </TableCell>
  );
};

export default React.memo(ActionsCell);
