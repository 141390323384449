import CommonCss from "nlib/common/common.module.scss";

import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { Avatar } from "nlib/ui";
import { getActiveOrganization, getAllUsersData } from "selectors/organizations";
import { getAllBusinessesData, getSelectedBusinessId } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { getUserData, getUserRestrictions } from "selectors/user";
import { useDispatch, useSelector } from "react-redux";
import ActionsCell from "./lib/ActionsCell";
import BusinessCell from "./lib/BusinessCell";
import BusinessesActions from "actions/BusinessesActions";
import Button from "nlib/ui/Button";
import DataConstants from "const/DataConstants";
import DebounceInput from "nlib/ui/DebounceInput";
import LastLoginAtCell from "./lib/LastLoginAtCell";
import NameCell from "./lib/NameCell";
import NoDataContent from "nlib/common/NoDataContent";
import NotificationsCell from "./lib/NotificationsCell";
import OrganizationsActions from "actions/OrganizationsActions";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import RoleCell from "./lib/RoleCell";
import SelectUserRole from "nlib/common/SelectUserRole";
import SelectUserStatus from "./lib/SelectUserStatus";
import StatusCell from "./lib/StatusCell";
import Table, { TableCell, TableHead, TableRow } from "nlib/ui/Table";
import UiActions from "actions/UiActions";
import UserRoles from "const/UserRoles";
import Utils from "utils/Utils";
import classNames from "classnames";
import moment from "moment";
import useEnvVars from "hooks/useEnvVars";

const searchString = (string, substring) => {
  if (!string || !substring) return false;

  return string.trim().toLocaleLowerCase().includes(substring.trim().toLocaleLowerCase());
};

const USER_ROLES_ORDER = [
  UserRoles.ACCOUNTANT_ADMIN.roleId,
  UserRoles.BUSINESS_ADMIN.roleId,
  UserRoles.ACCOUNTANT_MANAGER.roleId,
  UserRoles.BUSINESS_MANAGER.roleId
];

const UsersTab = ({ guestUsers }) => {
  const [{ search = "", role: searchRole, status: searchStatus, sortBy, sortOrder }, setEnvVars] = useEnvVars();

  const dispatch = useDispatch();

  const { uiTexts, messages } = useSelector(getTextsData);

  const userRestrictions = useSelector(getUserRestrictions);

  const usersData = useSelector(getAllUsersData);

  const userData = useSelector(getUserData);

  const { superAdminAccess, creatorId } = useSelector(getActiveOrganization);

  const selectedBusinessId = useSelector(getSelectedBusinessId);

  const allBusinessesData = useSelector(getAllBusinessesData);

  const [searchStr, setSearchStr] = useState(search);

  const showResetFiltersButton = [search, searchRole, searchStatus].some(Boolean);

  const currentTabUsersData = useMemo(() => {
    return usersData.filter((user) => guestUsers ? user.guestUser : !user.guestUser);
  }, [guestUsers, usersData]);

  const tableData = useMemo(() => {
    if (userRestrictions.usersRead) return [userData];

    const filtered = currentTabUsersData
      .filter(({ fullName, email, businessIds, role, holdUser, pendingInvite }) => {
        if (searchRole && role !== searchRole) return false;
        if (searchStatus) {
          const status = holdUser ? DataConstants.USER_STATUSES.DECLINED
            : (pendingInvite ? DataConstants.USER_STATUSES.PENDING : DataConstants.USER_STATUSES.APPROVED);

          if (searchStatus !== status) return false;
        }
        if (!search) return true;

        return searchString(fullName, search) || searchString(email, search) || searchString(
          allBusinessesData.filter(({ id }) => businessIds.includes(id)).map(({ name }) => name).join(","),
          search
        );
      });

    if (!sortBy) return filtered;

    return Utils.arraySort(filtered, (item) => {
      switch (sortBy) {
        case "businessIds": {
          if (!item.businessIds.length) return "a";

          const businessNames = allBusinessesData.filter(({ id }) => item.businessIds.includes(id)).map(({ name }) => name);

          if (!businessNames.length) return "c";

          return `b${businessNames.sort().join()}`;
        }
        case "notifications":
          if (!item.emailNotifications) return "0";
          if (!item.smsNotifications) return "1";

          return "2";
        case "status":
          if (item.holdUser) return "0";
          if (item.pendingInvite) return "1";

          return "2";
        case "role":
          return item.sub === creatorId ? -1 : USER_ROLES_ORDER.indexOf(item[sortBy]);
        case "lastLoginAt":
          return -moment(item.lastLoginAt).valueOf();
        default:
          return item[sortBy];
      }
    }, sortOrder === "asc");
  }, [
    userRestrictions.usersRead,
    currentTabUsersData,
    allBusinessesData,
    searchStatus,
    searchRole,
    sortOrder,
    creatorId,
    userData,
    search,
    sortBy
  ]);

  const onEditUser = useCallback(async(data, editableUserEmail) => {
    const {
      userEmail,
      userRole,
      userPhoneNumber,
      businessIds,
      emailNotifications,
      smsNotifications
    } = data;

    if (editableUserEmail) {
      await dispatch(
        OrganizationsActions.editUser({
          email: userEmail,
          phone: userPhoneNumber,
          role: userRole,
          businessIds,
          emailNotifications,
          smsNotifications
        })
      );
      if (selectedBusinessId && userData.email === editableUserEmail
          && businessIds.length && !businessIds.includes(selectedBusinessId)) {
        await dispatch(BusinessesActions.selectBusiness(businessIds[0]));
      }
    } else {
      await dispatch(
        OrganizationsActions.inviteUser({
          email: userEmail,
          role: userRole,
          businessIds,
          emailNotifications,
          smsNotifications,
          phone: userPhoneNumber
        })
      );
    }
    await dispatch(OrganizationsActions.fetchUsersList());
  }, [dispatch, selectedBusinessId, userData.email]);

  const handleInviteUserClick = useCallback(async() => {
    const result = await dispatch(UiActions.showUserDetailsWindow());

    if (result) onEditUser(result);
  }, [dispatch, onEditUser]);

  const openEditUserModal = useCallback(async(initialEmail) => {
    const disabled = !!(initialEmail && userRestrictions.usersUpdate);

    const result = await dispatch(UiActions.showUserDetailsWindow({ initialEmail, disabled }));

    if (result) onEditUser(result, initialEmail);
  }, [userRestrictions.usersUpdate, dispatch, onEditUser]);

  const handleEditClick = useCallback((initialEmail) => {
    openEditUserModal(initialEmail);
  }, [openEditUserModal]);

  const handleRemoveClick = useCallback(async(email) => {
    const modalResult = await dispatch(UiActions.showModal(messages.actionConfirm, null, true));

    if (modalResult) {
      const { pendingInvite } = usersData.find((item) => item.email === email);

      const result = await dispatch(OrganizationsActions.revokeUser(email, pendingInvite));

      if (result && email === userData.email) {
        window.location.reload();
      } else {
        dispatch(OrganizationsActions.fetchUsersList());
      }
    }
  }, [dispatch, messages.actionConfirm, usersData, userData.email]);

  const handleSearchChange = useCallback((value) => {
    setEnvVars({ search: value || null });
  }, [setEnvVars]);

  const handleSelectRoleChange = useCallback((value) => {
    setEnvVars({ role: value || null });
  }, [setEnvVars]);

  const handleSelectStatusChange = useCallback((value) => {
    setEnvVars({ status: value || null });
  }, [setEnvVars]);

  const handleSortChange = useCallback((value) => {
    setEnvVars(value);
  }, [setEnvVars]);

  const handleResetFiltersClick = useCallback(() => {
    setEnvVars({ search: null, role: null, status: null });
  }, [setEnvVars]);

  const handleTableRowClick = useCallback((event) => {
    if (!event.target.closest("a") && event.currentTarget.dataset.email) {
      openEditUserModal(event.currentTarget.dataset.email);
    }
  }, [openEditUserModal]);

  useEffect(() => {
    setSearchStr(search || "");
  }, [search]);

  return (
    <div className={Css.usersTab}>
      <div className={classNames(Css.actions, showResetFiltersButton && Css.sticky)}>
        <div className={Css.filters}>
          <DebounceInput
            cleanable
            active={!!search}
            iconBefore={Icons.MagnifyingGlass}
            placeholder={guestUsers ? messages.searchGuestUserPlaceholder : messages.searchUserPlaceholder}
            className={Css.text}
            value={searchStr}
            onChange={setSearchStr}
            onInputComplete={handleSearchChange} />
          <SelectUserRole
            filter
            active={!!searchRole}
            className={Css.select}
            value={searchRole}
            onChange={handleSelectRoleChange} />
          <SelectUserStatus
            filter
            active={!!searchStatus}
            className={Css.select}
            value={searchStatus}
            onChange={handleSelectStatusChange} />
          {showResetFiltersButton && (
            <Button
              danger large outline
              icon={Icons.X}
              className={Css.button}
              onClick={handleResetFiltersClick}>
              {uiTexts.resetFilters}
            </Button>
          )}
        </div>
        {!guestUsers && !userRestrictions.usersCreate && (
          <Button
            large primary
            className={Css.button}
            icon={Icons.UserPlus}
            onClick={handleInviteUserClick}>{uiTexts.inviteUser}</Button>
        )}
      </div>
      {tableData.length
        ? (
          <Table
            className={Css.table}
            sortBy={sortBy}
            sortOrder={sortOrder}
            onSortChange={handleSortChange}>
            <TableRow>
              <TableHead className={Css.nameCell} accessor="fullName" show={!guestUsers}>{uiTexts.name}</TableHead>
              <TableHead className={Css.emailCell} accessor="email">{uiTexts.email}</TableHead>
              <TableHead className={Css.statusCell} accessor="status">{uiTexts.status}</TableHead>
              <TableHead className={Css.roleCell} accessor="role">{uiTexts.role}</TableHead>
              <TableHead className={Css.notificationsCell} accessor="notifications">{uiTexts.notifications}</TableHead>
              <TableHead className={Css.businessCell} accessor="businessIds">{uiTexts.businesses}</TableHead>
              <TableHead className={Css.lastLoginAtCell} accessor="lastLoginAt">{uiTexts.recentActivity}</TableHead>
              <TableHead className={Css.actionsCell}>{uiTexts.actions}</TableHead>
            </TableRow>
            {tableData.map((item) => {
              const {
                id: userId,
                role,
                sub,
                email,
                guestUser,
                fullName,
                holdUser,
                pendingInvite,
                lastLoginAt,
                emailNotifications,
                smsNotifications,
                businessIds
              } = item;

              const currentUser = userData.email === email;

              const organizationCreator = creatorId === sub;

              const disabledEdit = pendingInvite || (currentUser && superAdminAccess);

              return (
                <TableRow
                  key={userId}
                  data-email={email}
                  className={classNames(Css.tableRow, currentUser && Css.currentUser, !disabledEdit && Css.pointer)}
                  onClick={disabledEdit ? undefined : handleTableRowClick}>
                  <NameCell
                    show={!guestUsers}
                    className={Css.nameCell}
                    fullName={guestUser ? null : fullName}
                    currentUser={currentUser} />
                  <TableCell className={Css.emailCell}>
                    {guestUsers && <Avatar className={Css.avatar} title={email} />}
                    <a className={CommonCss.darkText} title={email} href={`mailto:${email}`}>{email}</a>
                  </TableCell>
                  <StatusCell
                    className={Css.statusCell}
                    pendingInvite={pendingInvite}
                    holdUser={holdUser} />
                  <RoleCell
                    className={Css.roleCell}
                    role={role}
                    sub={sub}
                    email={email}
                    guestUser={guestUser} />
                  <NotificationsCell
                    className={Css.notificationsCell}
                    emailNotifications={emailNotifications}
                    smsNotifications={smsNotifications} />
                  <BusinessCell
                    className={Css.businessCell}
                    businessIds={businessIds} />
                  <LastLoginAtCell
                    className={Css.lastLoginAtCell}
                    lastLoginAt={lastLoginAt} />
                  <ActionsCell
                    className={Css.actionsCell}
                    email={email}
                    disabledEdit={pendingInvite || (currentUser && superAdminAccess)}
                    disabledRevoke={currentUser || organizationCreator || userRestrictions.usersDelete}
                    onEdit={handleEditClick}
                    onRevoke={handleRemoveClick} />
                </TableRow>
              );
            })}
          </Table>
        )
        : (
          <div className={Css.emptyState}>
            <NoDataContent
              icon={Icons.Users}
              title={uiTexts.noUsersFound} />
          </div>
        )}
    </div>
  );
};

export default React.memo(UsersTab);
